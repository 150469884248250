import React from "react";
import BlackList from "./black-list";
import ControlButtons from "../control-buttons/control-buttons";

class BlackListController extends React.Component{

    constructor(props){
        super(props);

        this.state = ({
            selectedTopics: this.props.selectedTopicsIds ? this.props.selectedTopicsIds.join(',') : '',
            topicBlackList: this.props.topicBlackList
        })
    }
    
    componentDidMount(){
        this.props.getMyTopics(this.props.role);
        if(this.props.selectedTopicsIds && this.props.selectedTopicsIds.length){
            this.props.getTopicBlackList(this.props.selectedTopicsIds, this.props.role === 'ROLE_MARKER' ? true : false)
        }
    }

    componentDidUpdate(){
        if(this.props.selectedTopicsIds && this.props.selectedTopicsIds !== undefined){
            if(this.state.selectedTopics !== (this.props.selectedTopicsIds && this.props.selectedTopicsIds.length || this.props.selectedTopicsIds !== undefined) ? this.props.selectedTopicsIds.join(',') : ''){
                this.setState({
                    selectedTopics: this.props.selectedTopicsIds.join(','),
                    topicBlackList: this.props.topicBlackList
                })
                this.props.setClearBlacklist();
                if(this.props.selectedTopicsIds.length)
                    this.props.getTopicBlackList(this.props.selectedTopicsIds, this.props.role === 'ROLE_MARKER' ? true : false)
            }
        }

    }

    getTopicName = (id, item) => {
        let k = this.props.topics ? this.props.topics.find((f,i)=>{return Number(f.id) === Number(id)}) : []
        return item ? k[item] : k
    }
    
    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    onTableItemsClick = (type, key, index) => {
        switch(type){
            case 'return':
                if(this.props.role === 'ROLE_MARKER')
                    this.props.removeFromBlackList(index, key);
                else
                    this.props.profileFromBlackList(index, key);
                setTimeout(() => {
                        this.props.getTopicBlackList(this.props.selectedTopicsIds, this.props.role === 'ROLE_MARKER' ? true : false)
                }, 300) 
                
                break;
        }
    }

    setTableValues = (tableValues) => {

        return tableValues.map((k, i)=>{
            
                if(typeof(k[k.length-1]) !== "object"){
                    let id = k[1]
                    let topicId = k[2]
                    k[0] = i+1
                    k[2] = this.getTopicName(k[2], 'name')
                    k[6] = this.props.getValue(k[6])
                    k.push(<ControlButtons keyId={id} callback={this.onTableItemsClick} items={["return"]}  index={topicId} />)
                }
            return k})

    }

    render() {
        
        return <BlackList data={this.props.topicBlackList.length && this.setTableValues(this.props.topicBlackList)} tableHeaderName={this.getTableHeader(this.props.tableHeader)} {...this.props}/>
    }
}

export default BlackListController;