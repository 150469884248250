import React, { useEffect, useState } from "react";
import { InputCheckBox, InputText } from "commons/input-controls";
import s from "./topic-menu.module.scss"

const TopicMenu = (props) => {
    const getSelectedAll = (topicsCount, selectedCount) => {
        return selectedCount === topicsCount ? true : false
    }

    const [items, setItems] = useState(props.items)
    const [selected, setSelected] = useState(props.selected);
    const [selectedSubtopic, setSelectedSubtopic] = useState(props.selectedSubtopics);
    const [showSubtopics, setShowSubtopics] = useState(props.showSubtopics ? props.showSubtopics : false);
    const [selectedAll, setSelectedAll] = useState(getSelectedAll(props.items && props.items.length ? props.items.length : 0, props.selected && props.selected.length ? props.selected.length : 0))

    useEffect( () => {
        setItems(props.items)
        setSelected(props.selected);
        setSelectedSubtopic(props.selectedSubtopics);
        setShowSubtopics(props.showSubtopics ? props.showSubtopics : false);
        setSelectedAll(getSelectedAll(props.items && props.items.length ? props.items.length : 0, props.selected && props.selected.length ? props.selected.length : 0))
    }, [props])

    const addSearchText = () => {

    }

    const selectAll = (e) => {
        let isSelected = !selectedAll
        isSelected ? props.setSelectedTopicsAll(items.map(k => {return {name: k.name, id: k.uuid}})) : props.setSelectedTopicsAll([])
    }

    const selectChecked = (uuid, isSelected, selected) => {
        props.setSelectedTopics({name: selected, id: uuid})
    }

    const selectCheckedSubtopics = (uuid, selected, selectedTopic, isSelected, sele) => {
        props.setSelectedSubtopics({name: selectedTopic, id: selected}, {name: sele, id: uuid})
    }

    const checkboxItems = items.map( (k, i) => 
        <div className={s.CheckItem} key={k.uuid}>
            <div className={s.CheckItemName}>
                <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selected.indexOf(k.uuid) !== -1} name={k.name} callback={selectChecked.bind(this, k.uuid)}/>
                <span>{k.name}</span>
            </div>

            {showSubtopics && k.subtopics ? k.subtopics.map((subtopic, j) => <div key={subtopic.uuid} className={s.CheckItemName+' '+s.SubtopicItemName}>
                <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selectedSubtopic.indexOf(subtopic.uuid) !== -1} name={subtopic.name} callback={selectCheckedSubtopics.bind(this, subtopic.uuid, k.uuid, k.name)}/>
                <span>{subtopic.name}</span>
            </div>) : null}
        </div>
    )

    return (
        <div className={s.TopicMenu}>
            {/*<div>
                <InputText placeholder={props.getValue('SEARCH_BY_TITLE')} callback={addSearchText} />
                
    </div>*/}
            <div className={s.ItemHeader}>
                <div className={s.CheckItem}>
                    <div className={s.CheckAllItem}>
                        <InputCheckBox isDisabled={props.isComparisonFetching} isChecked={selectedAll} callback={selectAll} name={'name+121'} title={props.getValue('CHOOSE_ALL')} />
                        <span>{props.getValue('CHOOSE_ALL')}</span>
                    </div>

                </div>
            </div>

            <div className={s.MenuBody}>
                { checkboxItems }
            </div>
        </div>
    )
}

export default TopicMenu;