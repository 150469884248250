import React from "react";
import ShortAnalyticPage from "./short-analytic";

class ShortAnalyticPageController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            topicData: props.topicData
        }
        
    }

    componentDidMount(){        
        if(this.props.isReturnedBack){
            this.props.setIsReturnedBack(false)
        }
        this.props.getMyTopics(this.props.role);
        this.props.setGrouping("DAILY")
    }

    getShortAnalyticTopics = (sortField, sortDirection) => {
        this.props.getMyTopics(this.props.role, sortField, sortDirection);
    }

    getTopicAnalytic = (uuid, mediatype) => {
        this.props.getShortAnalytic(uuid, mediatype)
    }

    componentWillUnmount(){
        //this.props.setThemeTableData([]);//clear topic
        this.props.setSubtopics([]);//clear subtopic
        if(this.props.toNull){
            this.props.setToNull(true)
        }
    }


    render(){
        return (this.props.topicData && this.props.topicData.length) ? <ShortAnalyticPage {...this.props} getShortAnalyticTopics={this.getShortAnalyticTopics} getTopicAnalytic={this.getTopicAnalytic} /> : null
    }
}

export default ShortAnalyticPageController