const SET_SELECTED_TOPICS = "SET-SELECTED-TOPICS";
const SET_SELECTED_SUBTOPICS = "SET-SELECTED-SUBTOPICS";
const SET_SELECT_ALL = "SET_SELECT_ALL";
const SET_SELECTED_SUBTOPICS_ALL = "SET-SELECTED-SUBTOPICS-ALL";

const TopicMenuData = {
    selectedTopics: [],
    selectedSubtopics: [],
    isFirst: true
}

export const TopicMenu = (state = TopicMenuData, action) => {
    let selectedTopics = [...state.selectedTopics]
    switch(action.type){
        case SET_SELECTED_TOPICS:
            let f = selectedTopics.findIndex(k => k.id === action.selected.id)
            if(f === -1)
                selectedTopics.push(action.selected)
            else
                selectedTopics.splice(f, 1)
            return {...state, selectedTopics: selectedTopics, selectedSubtopics: []}
        case SET_SELECTED_SUBTOPICS:
            let selectedSubtopics = [...state.selectedSubtopics]
            //let selectedTopicsInSub = [...state.selectedTopics]
            let fs = selectedSubtopics.findIndex(k => k.id === action.selected.id)
            if(selectedTopics.findIndex(k => k.id === action.selectedTopic.id) === -1)
                selectedSubtopics = []
            if(fs === -1)
                selectedSubtopics.push(action.selected)
            else
                selectedSubtopics.splice(fs, 1)
            return {...state, selectedTopics: [action.selectedTopic], selectedSubtopics: selectedSubtopics}
        case SET_SELECTED_SUBTOPICS_ALL:

            return {...state, selectedTopics: action.selected, selectedSubtopics: action.selectedSubtopics}
        case SET_SELECT_ALL:
            return {...state, selectedTopics: action.selected, selectedSubtopics: []}
        default: return {...state}
    }
}

export default TopicMenu;

export const setSelectedTopicsAll = (selectedTopics) => {
    return {
        type: SET_SELECT_ALL,
        selected: selectedTopics
    }
}

export const setSelectedTopics = (selectedTopics) => {
    return {
        type: SET_SELECTED_TOPICS,
        selected: selectedTopics
    }
}

export const setSelectedSubtopics = (selectedTopic, selectedSubtopics) => {
    return {
        type: SET_SELECTED_SUBTOPICS,
        selected: selectedSubtopics,
        selectedTopic: selectedTopic
    }
}

export const setSelectedSubtopicsAll = (selectedTopic, selectedSubtopics) => {
    return {
        type: SET_SELECTED_SUBTOPICS_ALL,
        selected: selectedTopic,
        selectedSubtopics: selectedSubtopics
    }
}